import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";

import SearchResult from "./SearchResult/SearchResult";
import styled from "styled-components";

import { ReactComponent as ArrowSvg } from "./arrowleft.svg";

type SearchProps = {
  refImgFile: File;
  reset: () => void;
};

type SearchByTextProps = {
  text: string;
  reset: () => void;
};

const SEARCH_MUTATION = gql`
  mutation Search($file: Upload!) {
    search(imgRef: $file) {
      assets {
        projectOwners {
          id
          name
          url
        }
        project {
          id
          title
          url
        }
        asset {
          id
          filename
          seqNum
          size {
            width
            height
          }
        }
      }
      ids
    }
  }
`;

const SEARCH_TXT_MUTATION = gql`
  mutation SearchByText($text: String!) {
    searchByText(text: $text) {
      assets {
        projectOwners {
          id
          name
          url
        }
        project {
          id
          title
          url
        }
        asset {
          id
          filename
          seqNum
          size {
            width
            height
          }
        }
      }
      ids
    }
  }
`;

export function Search({ refImgFile, reset }: SearchProps) {
  const [doSearch, { data, loading, error }] = useMutation(SEARCH_MUTATION);

  useEffect(() => {
    doSearch({ variables: { file: refImgFile } });
  }, [refImgFile, doSearch]);

  if (error) {
    return <StatusBox>Error</StatusBox>;
  }

  if (loading) {
    return <StatusBox>Searching...</StatusBox>;
  }

  if (!data) {
    return <StatusBox>Waiting for data...</StatusBox>;
  }

  return (
    <SearchResult
      data={data.search.assets}
      refImgFile={refImgFile}
      reset={reset}
    />
  );
}

export function SearchByText({ text, reset }: SearchByTextProps) {
  const [doSearch, { data, loading, error }] = useMutation(SEARCH_TXT_MUTATION);

  useEffect(() => {
    doSearch({ variables: { text } });
  }, [text, doSearch]);

  if (error) {
    return <StatusBox>Error</StatusBox>;
  }

  if (loading) {
    return <StatusBox>Searching...</StatusBox>;
  }

  if (!data) {
    return <StatusBox>Waiting for data...</StatusBox>;
  }

  return (
    <>
      <SearchTextContainer>
        <ResetButton onClick={reset}><ArrowSvg /></ResetButton>
        {text}
      </SearchTextContainer>
      <SearchResult
        data={data.searchByText.assets}
        searchText={text}
        reset={reset}
      />
    </>
  );
}

const SearchTextContainer = styled.div`
  position: relative;
  text-align: center;
  font-size: 36px;
  padding: 8px 0;
`;

const ResetButton = styled.button`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: 0 none;
  cursor: pointer;
`;

const StatusBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
`;
