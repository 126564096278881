import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { Logo } from "./Logo/Logo";
import { ReactComponent as ArrowSvg } from "./arrowleft.svg";

import { track } from "./utils/track";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f3;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgb(35, 32, 41);
`;

const LogoLink = styled.a`
  svg {
    display: block;
  }
`;

const TopPanel = styled.div`
  height: 65px;
  box-sizing: border-box;
  padding: 0 16px;
  background-color: white;
  border-bottom: 1px solid #d8d7da;
  display: flex;
  align-items: center;
  z-index: 200;
`;

const Spacer = styled.div`
  flex: 1 1 auto;
`;

const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
  min-height: 0;
  padding: 24px 0;
`;

const BackButton = styled.button`
  border: 0 none;
  background: transparent;
  padding: 0;
  position: absolute;
  top: 32px;
  left: 16px;
  cursor: pointer;
`;

const AboutButton = styled.button`
  flex: 0 0 auto;
  border: 0 none;
  background: none;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`;

const Title = styled.h1`
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  color: rgb(35, 32, 41);
  margin: 0 0 24px;
`;

const DropContainer = styled.div`
  position: relative;
  width: 700px;
  margin: 0 auto;
`;

const Box = styled.div`
  padding: 20px 24px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 16px;
  border: 1px solid rgb(216, 215, 218)
`;

const AboutContent = styled.div``;

const FileButton = styled.button`
  display: inline;
  background: none;
  border: 0 none;
  padding: 0;
  font: inherit;
  color: #5d18ce;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const Hint = styled.div`
  padding: 8px;
  text-align: center;
`;

const Separator = styled.div`
  margin: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

const SeparatorLine = styled.div`
  height: 0;
  width: 24px;
  border-top: 1px solid ;
  color: #d8d7da;
`;

const SeparatorText = styled.div`
  color: #7e7a85;
`;

const TextSearchForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextInput = styled.input`
  margin-top: 8px;
  background-color: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(190, 188, 194);
  width: 575px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  outline: transparent solid 3px;
  transition: background-color 200ms ease 0s, outline 200ms ease 0s, color 200ms ease 0s, box-shadow 200ms ease 0s;
  font: inherit;
  color: inherit;

  &:hover {
    border-color: rgb(190, 188, 194);
    background: rgb(255, 255, 255);
    box-shadow: rgba(93, 24, 206, 0.25) 0px 0px 0px 3px;
  }
`;

const TextSearchButton = styled.button`
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(93, 24, 206);
  padding: 10px 15px;
  border-radius: 8px;
  background: transparent;
  color: rgb(93, 24, 206);
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  display: block;
  margin-top: 16px;
  position: relative;
  cursor: pointer;

  &:hover::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0px;
    border-radius: inherit;
    background-color: rgba(126, 122, 133, 0.1);
    pointer-events: none;
  }
`;

const DndCover = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  pointer-events: none;
  opacity: ${(p) => (p.active ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  border-radius: 16px;
`;

const DndCoverContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(93, 24, 206, 0.1);
  border: 1px dashed rgb(93, 24, 206);
  border-radius: 16px;
  color: rgb(93, 24, 206);

  p {
    margin: 0 0 8px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
  }
`;

type FileStepProps = {
  setFile: (file: File) => void;
  setSearchText: (text: string) => void;
};

function FileStep({ setFile, setSearchText }: FileStepProps) {
  const [isAboutOpened, setAboutOpened] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        track("Upload file");
        setFile(acceptedFiles[0]);
      }
    },
    [setFile]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, open } = useDropzone({
    onDrop,
    multiple: false,
    noClick: true,
  });

  const handleFormSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      const input = event.currentTarget.querySelector("input");
      if (!input || input.value.length === 0) {
        return;
      }
      track("Search by text", { text: input.value });
      setSearchText(input.value);
    },
    [setSearchText]
  );

  const handleAboutClick = useCallback(() => {
    setAboutOpened(true);
  }, []);

  const handleBackClick = useCallback(() => {
    setAboutOpened(false);
  }, []);

  return (
    <Container>
      <TopPanel>
        <LogoLink href="https://app.baselance.co/">
          <Logo />
        </LogoLink>
        <Spacer />
        <AboutButton onClick={handleAboutClick}>About</AboutButton>
      </TopPanel>
      <Content>
        <Title>AI Search (beta)</Title>
        {isAboutOpened && <BackButton onClick={handleBackClick}><ArrowSvg /></BackButton>}
        {isAboutOpened ? (
          <DropContainer>
            <Box>
              <AboutContent>
                <h2>About</h2>
                <p>We are excited to announce the launch of our new service, your AI assistant for discovering artworks and artists. We are currently in beta, and we are looking for feedback from our users.</p>
                <p>Have you ever seen a beautiful painting or illustration online but couldn't figure out who created it or where to find more of their work? Or maybe you're looking for artworks that match a certain description but don't know where to start searching? That's where Baselance AI Search comes in!</p>
                <p>Our new service allows you to search for artworks and artists simply by providing an image or text description. Our advanced artificial intelligence system will search through our database to find matches and provide you with similar artworks from popular portfolio networks.</p>
                <p>During our beta period, our database is focused solely on artworks from Behance. Behance is home to over 15 million creative projects spanning illustrations, graphic design, photography, and more. We are actively working to expand our artwork sources to additional platforms, but for now you can discover amazing Behance talent with our service.</p>
                <p>Thank you for your interest! Our mission is to make discovering inspirational artworks and artists easier than ever. We can't wait for you to start searching our AI-powered database.</p>
                <TextSearchButton onClick={handleBackClick}>Start searching</TextSearchButton>

              </AboutContent>
            </Box>
          </DropContainer>
        ) : (
          <DropContainer {...getRootProps()}>
            <Box>
              <input {...getInputProps()} />
              <Hint>
                Just drag'n'drop an image here, or <FileButton onClick={open}>select a file</FileButton>
                <br />
                and we will find similar artworks
              </Hint>
              <Separator>
                <SeparatorLine />
                <SeparatorText>or</SeparatorText>
                <SeparatorLine />
              </Separator>
              <TextSearchForm onSubmit={handleFormSubmit}>
                <Hint>Search artworks using a text description</Hint>
                <TextInput placeholder="cartoon style dog" />
                <TextSearchButton>Text Search</TextSearchButton>
              </TextSearchForm>
            </Box>
            <DndCover active={isDragActive}>
              <DndCoverContent>
                <p>Drop image here</p>
                <span>png, jpeg, gif, tiff, webp</span>
              </DndCoverContent>
            </DndCover>
          </DropContainer>
        )}
      </Content>

    </Container >
  );
}

export default FileStep;
