import React, { useState, useEffect } from "react";
import { PhotoAlbum, RenderPhoto } from "react-photo-album";
import styled from "styled-components";

type ProjectOwner = {
  id: string;
  name: string;
  url: string;
};

type Project = {
  id: string;
  title: string;
  url: string;
};

type Asset = {
  id: string;
  filename: string;
  seqNum: number;
  size: {
    width: number;
    height: number;
  };
};

type SeatchProjectsResult = {
  projectOwners: ProjectOwner[];
  project: Project;
  assets: Asset[];
};

type SearchAssetsResult = {
  projectOwners: ProjectOwner[];
  project: Project;
  asset: Asset;
};

type SearchResultProps = {
  data: SearchAssetsResult[];
  refImgFile?: File;
  searchText?: string;
  reset: () => void;
};

type GalleryImage = {
  src: string;
  width: number;
  height: number;
  url: string | null;
  resetRef: null | (() => void);
};

function SearchResult({ data, refImgFile, reset }: SearchResultProps) {
  let [loading, setLoading] = useState(true);
  let [images, setImages] = useState<GalleryImage[]>([]);
  let [refImage, setRefImage] = useState<GalleryImage | null>(null);

  useEffect(() => {
    if (refImgFile) {
      let rejected = false;
      if (FileReader) {
        const reader = new FileReader();
        reader.onload = () => {
          if (rejected) return;

          loadImage(reader.result as string).then((img) => {
            if (rejected) return;

            setRefImage({
              src: img.src,
              width: img.naturalWidth,
              height: img.naturalHeight,
              url: null,
              resetRef: reset,
            });
          });
        };
        reader.readAsDataURL(refImgFile);
      }

      return () => {
        rejected = true;
      };
    }
  }, [refImgFile, setRefImage, reset]);

  useEffect(() => {
    let rejected = false;
    setLoading(true);
    const promises = data.map(({ asset, project }): Promise<GalleryImage> => {
      let src = asset_url(asset);
      let gallery_image = {
        src,
        width: 0,
        height: 0,
        url: project.url,
        resetRef: null,
      };
      if (asset.size) {
        gallery_image.width = asset.size.width;
        gallery_image.height = asset.size.height;
        return Promise.resolve(gallery_image);
      } else {
        return loadImage(src).then((img) => {
          gallery_image.width = img.naturalWidth;
          gallery_image.height = img.naturalHeight;
          return gallery_image;
        });
      }
    });
    Promise.all(promises).then((images) => {
      if (rejected) return;

      setImages(images);
      setLoading(false);
    });

    return () => {
      rejected = true;
    };
  }, [data, setImages, setLoading]);

  if (loading) {
    return <div>Building gallery...</div>;
  }

  let gallery = refImage ? [refImage, ...images] : images;

  return (
    <PhotoAlbum
      spacing={1}
      layout="rows"
      photos={gallery}
      renderPhoto={DefaultPhotoRenderer}
    />
  );
}

const ImageItem = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const ImageLink = styled.a`
  position: relative;

  &:hover ${ImageItem} {
    filter: brightness(92%);
  }
`;

const CloseRefButton = styled.button`
  display: block;
  padding: 0;
  margin: 0;
  border: 0 none;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  font-size: 48px;
  background: rgba(255, 255, 255, 0.25);
  text-align: center;
  border-radius: 50%;
  line-height: 1;

  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

const RefImageContainer = styled.div`
  position: relative;
`;

const DefaultPhotoRenderer: RenderPhoto = ({ photo, imageProps }) => {
  const { src, alt, style, srcSet, sizes, ...rest } = imageProps;
  const img = (
    <ImageItem
      src={src}
      alt={alt}
      {...(srcSet ? { srcSet, sizes } : null)}
      {...rest}
    />
  );
  let data = photo as GalleryImage;

  if (data.url) {
    return (
      <ImageLink
        href={data.url}
        target="_blank"
        rel="noreferrer noopener"
        style={style}
      >
        {img}
      </ImageLink>
    );
  } else if (data.resetRef) {
    return (
      <RefImageContainer style={style}>
        {img}
        <CloseRefButton onClick={data.resetRef}>×</CloseRefButton>
      </RefImageContainer>
    );
  } else {
    return <div />;
  }
};

function fmt03(num: number): string {
  if (num < 10) {
    return `00${num}`;
  }

  if (num < 100) {
    return `0${num}`;
  }

  return `${num}`;
}

const loadImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", (err) => reject(err));
    img.src = url;
  });

function asset_url(asset: Asset): string {
  const part1 = asset.seqNum % 1000;
  const rest1 = Math.floor(asset.seqNum / 1000);
  const part2 = rest1 % 1000;
  const rest2 = Math.floor(rest1 / 1000);
  const part3 = rest2 % 1000;
  const part4 = Math.floor(rest2 / 1000);

  return `https://baseek.sfo3.digitaloceanspaces.com/assets/${part4}/${fmt03(part3)}/${fmt03(part2)}/${fmt03(part1)}/${asset.filename}`;
}

export default SearchResult;
