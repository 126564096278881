import React, { useState, useCallback } from "react";

import FileStep from "./FileStep";
import { Search, SearchByText } from "./Search";

function App() {
  const [file, setFile] = useState<File | null>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const handleReset = useCallback(() => {
    setFile(null);
    setSearchText(null);
  }, []);

  if (file) {
    return <Search refImgFile={file} reset={handleReset} />;
  }

  if (searchText) {
    return <SearchByText text={searchText} reset={handleReset} />;
  }

  return <FileStep setFile={setFile} setSearchText={setSearchText} />;
}

export default App;
