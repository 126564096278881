import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import { waitFor } from './utils/waitFor';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: "/api/graphql",
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <React.Fragment>
        <GlobalStyle />
        <App />
      </React.Fragment>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

let authQuery = "{ viewer { id email name heapId }}";

fetch("https://app.baselance.co/graphql", {
  method: "POST",
  mode: "cors",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify({ query: authQuery }),
})
  .then(response => response.json())
  .then(json => {
    let viewer = json?.data?.viewer;
    if (viewer?.heapId) {
      waitFor(
        () => {
          const { heap } = window as any;
          return !!(heap && heap.identify);
        },
        () => {
          const { heap } = window as any;
          heap.identify(viewer.heapId);
        },
        60000
      );

    }
  });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
